<template>
	<div class="index">
		<div class="container">
			<div class="top">欢迎使用 自助打印终端，请选择身份进入</div>

			<div class="box">
				<div class="item" @click="go('/Tourists')">游客</div>
				<div class="item" @click="member()">会员</div>
			</div>
			<!-- <div @click="go('/demo')">测试</div> -->
		</div>


	</div>
</template>

<script>
	// @ is an alias to /src
	import Time from '@/components/time.vue'


	export default {
		name: 'Home',
		components: {
			Time
		},
		data() {
			return {}
		},
		created() {

		},
		methods: {
			go(url) {
				this.$router.push({
					path: url
				})
			},
			member(){
				//console.log('aa')
				if(!sessionStorage.getItem('token')){
					this.go('/member')
				//	location.href="https://open.weixin.qq.com/connect/qrconnect?appid=wx9190d04b49c38d18&redirect_uri=https%3A%2F%2Fprint.ynlothar.com%2Fapi%2Flogin%2FPcScanUrl&response_type=code&scope=snsapi_login&state=STATE#wechat_redirect";
				     // console.log('aa')
					  //location.href = "http://www.baidu.com"
				}else{
					console.log('bb')
					this.go('/member')
				}
				
			}

		}
	}
</script>


<style scoped lang="scss">
	.index {


		.container {

			.top {
				color: #fff;
			}

			.box {

				width: 800px;
				margin: 0 auto;
				display: flex;
				margin-top: 150px;
				justify-content: space-between;

				.item {
					width: 350px;
					height: 200px;
					background: #f1dd59;
					color: #fff;
					font-size: 100px;
					text-align: center;
					line-height: 200px;
					cursor: pointer;

					&:nth-child(1) {
						background: #18E3B3;
					}
				}
			}

		}


	}
</style>
